<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div
            class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
        >
          <h3>DialFrom263 Airtime Purchase History</h3>
          <div>
            <router-link
                :to="{ name: 'dial_from_263' }"
                class="ps-btn btn-sm"
                v-if="auth.isAuthenticated"
            ><i class="icon-list"></i> Buy DialFrom263
            </router-link>
            &nbsp;&nbsp;
          </div>
        </div>

        <div class="table-responsive">
          <div class="table-responsive">
            <table class="table ps-table ps-table--vendor table-bordered">
              <thead>
              <tr>
                <th>Date</th>
                <th>Phone Number</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="airtimePurchase in airtimePurchases" :key="airtimePurchase.id">
                <td>{{ airtimePurchase.created_at | formatDate }}</td>
                <td>
                  <span class="text-muted">{{ airtimePurchase.phone_number }}</span>
                </td>
                <td>{{ airtimePurchase.amount }}</td>
                <td>{{ airtimePurchase.status }}</td>
                <td>
                  <button
                      v-if="airtimePurchase.status === 'valid'"
                      class="btn-info btn btn-sm mr-2"
                  >
                    Cancel
                  </button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr
                  v-if="(!airtimePurchases || total === 0) && !isLoading"
              >
                <td colspan="6" class="text-center m-4 text-danger p-4">
                  No airtime purchase history available
                </td>
              </tr>
              <tr v-else-if="isLoading">
                <td colspan="6" class="text-center m-4 text-danger">
                  Loading airtime purchase history...
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "AirtimePurchases",
  data() {
    return {
      airtime: {},
      airtimePurchases: [],
      total: 0,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  watch: {
    $route(to, from) {
      this.getAirtimePurchases();
    },
  },
  mounted() {
    this.getAirtimePurchases();
  },
  methods: {
    getAirtimePurchases()
    {
      this.isLoading = true;
      axios.get("orders/airtimes").then((response) => {
        this.airtimePurchases = response.data.data;
        this.total = response.data.total;
        this.isLoading = false;
      });
    },
  },
};
</script>
